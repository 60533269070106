.taxback-date-picker {
  position: absolute !important;
  right: 0;
  z-index: 99 !important;
}

button[class*="materialui-daterange-picker-makeStyles-filled"] {
  background-color: #F4C414 !important;
}



button[class*="materialui-daterange-picker-makeStyles-outlined"] {
  border: 1px solid #F4C414 !important;
}



#chart {
  max-width: 760px;
  margin: 35px auto;
}

.arrow_box {
  position: relative;
  background: #FCFCFE;
  padding: 10px;
  border: 2px solid #FCFCFE;
}
.arrow_box:after, .arrow_box:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

#chart .apexcharts-tooltip {
  color: #fff;
  transform: translateX(10px) translateY(10px);
  overflow: visible !important;
  white-space: normal !important;
}

#chart .apexcharts-tooltip div {
  padding: 10px;
  display: inline-block;
}
